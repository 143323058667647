import AppLayout from '@/layouts/app-layout';
import HomeView from '@/components/app/home/home-view';

export default function Home() {


    return (
        <AppLayout padded={false}>
            <HomeView  />
        </AppLayout>
    );
}

"use client"

import { useState } from "react"
import { ArrowRight, Search } from "lucide-react"
import Image from "@/components/image"

import { Badge } from "@/components/ui/badge"
import Button from "@/components/button"
import { Input } from "@/components/ui/input"
import routes from '@/lib/routes';
import { router } from '@inertiajs/react';

export default function HeroBanner() {
    const [search, setSearch] = useState('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

            router.get(`${routes.workshops}?search=${search}`);
    }

    return (
        <div className="relative w-full overflow-hidden">
            {/* Background with gradient overlay */}
            <div className="absolute inset-0 z-0">
                {/*<Image*/}
                {/*    src="/placeholder.svg?height=600&width=1200&text=Learn+New+Skills"*/}
                {/*    alt=""*/}
                {/*    fill*/}
                {/*    className="object-cover"*/}
                {/*    priority*/}
                {/*/>*/}
                <div className="absolute inset-0 bg-gradient-to-r from-background/95 via-background/80 to-background/60" />
            </div>

            {/* Decorative blobs */}
            <div className="absolute top-20 right-[20%] h-72 w-72 rounded-full bg-primary/20 blur-3xl filter animate-blob" />
            <div className="absolute bottom-20 left-[20%] h-72 w-72 rounded-full bg-blue-500/20 blur-3xl filter animate-blob animation-delay-2000" />
            <div className="absolute top-40 left-[45%] h-72 w-72 rounded-full bg-purple-500/20 blur-3xl filter animate-blob animation-delay-4000" />

            <div className="container relative z-10 mx-auto px-4 py-8 sm:px-8 max-w-screen-2xl">
                <div className=" max-w-3xl text-center md:text-left">
                    <Badge variant="outline" className="mb-4 border-primary/50 text-primary">
                        New workshops added weekly
                    </Badge>
                    <h1 className="text-3xl font-extrabold tracking-tight sm:text-4xl md:text-5xl">
                        Discover your next
                        <span className="block text-primary">learning adventure</span>
                    </h1>
                    <p className="mt-4 text-md text-muted-foreground">
                        Join thousands of learners expanding their skills with expert-led workshops in programming, design,
                        business, and more.
                    </p>

                    <form onSubmit={handleSubmit}>
                        <div className="mt-6 flex flex-col sm:flex-row items-center gap-4">
                                <div className="relative w-full max-w-md">
                                    <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                                    <Input
                                        type="search"
                                        name="search"
                                        defaultValue={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="What do you want to learn today?"
                                        className="w-full pl-9 pr-4 py-6"
                                    />
                                </div>
                                <Button size="lg" className="w-full sm:w-auto cursor-pointer">
                                    Find Workshops
                                    <ArrowRight className="ml-2 h-4 w-4" />
                                </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


import { cn } from '@/lib/utils';

interface CallToActionsProps {
    title: string;
    description: string;
    actions: {
        title: string;
        description: string;
        link: string;
    }[];
    children?: React.ReactNode;
    className?: string;
}

export default function CallToAction({title, description, actions, children, className} : CallToActionsProps) {

    return (
        <div className={cn("my-6 py-6 px-4 bg-muted rounded-lg", className)}>
            <div className=" mx-auto"><h3 className="text-xl font-semibold mb-2">{title}</h3>
            </div>
            <p className="text-muted-background mb-6">{description}</p>

                {children}

                {actions.map((action, index) => (
                    <div key={index} className="mb-4">
                        <a
                            href={action.link}
                            className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&amp;_svg]:pointer-events-none [&amp;_svg]:size-4 [&amp;_svg]:shrink-0 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
                            {action.title}
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" className="lucide lucide-arrow-right ml-2 h-4 w-4">
                                <path d="M5 12h14"></path>
                                <path d="m12 5 7 7-7 7"></path>
                            </svg>
                        </a>
                    </div>
                ))}
            {/*    <a*/}
            {/*    className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&amp;_svg]:pointer-events-none [&amp;_svg]:size-4 [&amp;_svg]:shrink-0 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"*/}
            {/*    href="/user/workshops">Browse All Workshops*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
            {/*         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"*/}
            {/*         className="lucide lucide-arrow-right ml-2 h-4 w-4">*/}
            {/*        <path d="M5 12h14"></path>*/}
            {/*        <path d="m12 5 7 7-7 7"></path>*/}
            {/*    </svg>*/}
            {/*</a>*/}
            {/*    </div>*/}
        </div>
    );
}


export const BecomeAnInstructorCTA = () => {
    return (
        <div className="rounded-lg border bg-card text-card-foreground shadow-sm my-12 overflow-hidden">
            <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 p-6 md:p-8">
                    <div className="flex flex-col space-y-1.5 p-0">
                        <div className="font-semibold tracking-tight text-2xl">Become an Instructor</div>
                        <div className="text-sm text-muted-foreground">Share your expertise with thousands of learners
                            worldwide
                        </div>
                    </div>
                    <div className="p-0 py-4">
                        <ul className="space-y-2">
                            <li className="flex items-start gap-2">
                                <div className="rounded-full p-1 bg-primary/10 text-primary mt-0.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round" className="lucide lucide-users h-3 w-3">
                                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="9" cy="7" r="4"></circle>
                                        <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                    </svg>
                                </div>
                                <span className="text-sm">Reach thousands of eager students</span></li>
                            <li className="flex items-start gap-2">
                                <div className="rounded-full p-1 bg-primary/10 text-primary mt-0.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round" className="lucide lucide-users h-3 w-3">
                                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="9" cy="7" r="4"></circle>
                                        <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                    </svg>
                                </div>
                                <span className="text-sm">Earn revenue from your expertise</span></li>
                            <li className="flex items-start gap-2">
                                <div className="rounded-full p-1 bg-primary/10 text-primary mt-0.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round" className="lucide lucide-users h-3 w-3">
                                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="9" cy="7" r="4"></circle>
                                        <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                    </svg>
                                </div>
                                <span className="text-sm">Get support with marketing and production</span></li>
                        </ul>
                    </div>
                    <div className="flex items-center p-0 pt-2"><a
                        className="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&amp;_svg]:pointer-events-none [&amp;_svg]:size-4 [&amp;_svg]:shrink-0 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
                        href="/instructor/apply">Apply Now</a></div>
                </div>
                <div className="md:w-1/2 relative min-h-[200px] md:min-h-0"><img alt="Become an instructor"
                                                                                 loading="lazy" decoding="async"
                                                                                 data-nimg="fill"
                                                                                 className="object-cover"
                                                                                 src="/placeholder.svg?height=300&amp;width=400&amp;text=Teach" />
                </div>
            </div>
        </div>
    );
}

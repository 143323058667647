import { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import Button from '@/components/button';

export function HorizontalScrollArea({header, children, showNav = true}: {header: ReactNode, children: ReactNode, showNav?: boolean}) {
    const scrollRef: React.RefObject<HTMLDivElement | null> = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);

    // Check if scroll buttons should be visible
    const checkScrollButtons = () => {
        // With shadcn ScrollArea, we need to find the actual scrollable element
        if (scrollRef.current) {
            // Look for the shadcn scrollable element
            const scrollElement = scrollRef.current.querySelector('[data-radix-scroll-area-viewport]');

            if (scrollElement) {
                const { scrollLeft, scrollWidth, clientWidth } = scrollElement;
                setCanScrollLeft(scrollLeft > 0);
                setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 10); // 10px buffer
            }
        }
    };

    // Set up scroll detection
    useEffect(() => {
        if (!scrollRef.current) return;

        // Find the actual scrollable element in the shadcn ScrollArea
        const scrollElement = scrollRef.current.querySelector('[data-radix-scroll-area-viewport]');

        if (!scrollElement) return;

        // Initial check
        checkScrollButtons();

        // Check on scroll
        const handleScroll = () => checkScrollButtons();
        scrollElement.addEventListener('scroll', handleScroll);

        // Check on resize
        window.addEventListener('resize', checkScrollButtons);

        // Clean up
        return () => {
            scrollElement.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', checkScrollButtons);
        };
    }, []);

    // Scroll buttons handler
    const scroll = (direction: 'left' | 'right') => {
        if (!scrollRef.current) return;

        // Find the shadcn scrollable element
        const scrollElement = scrollRef.current.querySelector('[data-radix-scroll-area-viewport]');

        if (scrollElement) {
            const scrollAmount = scrollElement.clientWidth * 0.75;
            const newScrollLeft =
                direction === 'left'
                    ? scrollElement.scrollLeft - scrollAmount
                    : scrollElement.scrollLeft + scrollAmount;

            scrollElement.scrollTo({
                left: newScrollLeft,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="relative w-screen max-w-full overflow-hidden" style={{
            marginRight: "-50vw"
        }}>
            <div className="flex items-center justify-between">
                {header}

                {showNav && (
                    <div className="flex space-x-2">
                        <Button
                            variant="outline"
                            size="icon"
                            onClick={() => scroll('left')}
                            disabled={!canScrollLeft}
                            className="h-8 w-8 rounded-full"
                            aria-label="Scroll left"
                        >
                            <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="outline"
                            size="icon"
                            onClick={() => scroll('right')}
                            disabled={!canScrollRight}
                            className="h-8 w-8 rounded-full"
                            aria-label="Scroll right"
                        >
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    </div>
                )}

            </div>

            <Separator className="my-4" />

            <div className="relative" ref={scrollRef}>
                <ScrollArea>
                    <div className={"py-1"}>
                        {children}
                    </div>
                    <ScrollBar orientation="horizontal" />
                </ScrollArea>
            </div>
        </div>
    );
}

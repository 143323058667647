import { useState, useRef, useEffect } from "react"
import { ChevronLeft, ChevronRight, Play, Star } from "lucide-react"
import Link from "@/components/link"
import Image from "@/components/image"

import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { QuickPicksSkeleton } from '../skeletons/workshops-quick-picks-skeleton';
import { WorkshopCard } from '@/components/app/workshop/workshop-card';
import routes from '@/lib/routes';
import { Workshop } from '@/types/app/workshop';

export function QuickPicksSection({
  workshops,
  isLoading = false
}: {
    workshops: Workshop[]
    isLoading?: boolean
}) {
    const [initialLoad, setInitialLoad] = useState(true);

    // After first render with data, mark initial load as complete
    useEffect(() => {
        if (workshops?.length && initialLoad) {
            setInitialLoad(false);
        }
    }, [workshops, initialLoad]);

    // Only show skeleton on first load and when loading
    if (initialLoad && isLoading) {
        return <QuickPicksSkeleton />
    }

    // Don't render anything if no workshops
    if(!workshops?.length) {
        return null;
    }

    return (
        <section className="">
            <div className="mb-6 flex items-center justify-between">
                <h2 className="text-2xl font-bold tracking-tight">Quick picks</h2>
                <div className=" items-center gap-2">
                    <Button variant="outline" size="sm" asChild>
                        <Link href={`${routes.workshops}/?collection=quick-picks`}>View all</Link>
                    </Button>
                </div>
            </div>

            <div
                className="grid grid-cols-1 md:grid-cols-3 gap-4 pb-4"
            >
                {workshops?.map((workshop, index) => (
                    <div
                        key={workshop.id || index}
                        className="group flex-shrink-0 cursor-pointer transition-transform hover:scale-[1.02]"
                    >
                        <WorkshopCard workshop={workshop} type={'mini'} />
                    </div>
                ))}
            </div>
        </section>
    )
}

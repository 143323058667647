import { WorkshopCard } from '@/components/app/workshop/workshop-card';
import { Workshop } from '@/types/app/workshop';
import { HorizontalScrollArea } from '@/components/horizontal-scroll-area';
import { WorkshopCardAspectRatio, WorkshopCardSize } from '@/components/app/workshop/cards/cards';
import { WorkshopCollectionSkeleton } from '@/components/app/skeletons/workshops-cards-skeleton';
import { useState, useEffect } from 'react';

interface CollectionRowProps {
    collection: {
        type: string
        title: string
        description?: string
        icon?: any
        items: Workshop[]
    }
    cardSize?: WorkshopCardSize
    aspectRatio?: WorkshopCardAspectRatio
    cta?: React.ReactNode
    isLoading?: boolean
}

export function WorkshopCollectionRow({
                                          collection,
                                          cardSize = "medium",
                                          aspectRatio = "portrait",
                                          cta,
                                          isLoading = false
                                      }: CollectionRowProps) {
    const [initialLoad, setInitialLoad] = useState(true);

    // After first render, mark the initial load as complete
    useEffect(() => {
        if (collection?.items && initialLoad) {
            setInitialLoad(false);
        }
    }, [collection?.items, initialLoad]);

    // ShowProject skeleton only during initial load
    if (initialLoad && isLoading) {
        return <WorkshopCollectionSkeleton />;
    }

    // After initial load, if there are no items, we could either:
    // 1. Return null (don't show anything)
    // 2. ShowProject an empty state message
    // 3. ShowProject the header without items
    if (!collection?.items?.length) {
        return null; // Or handle empty state differently if needed
    }

    return (
        <div>
            <HorizontalScrollArea header={
                <div className="flex items-center gap-2">
                    {collection.icon && (
                        <div className="rounded-full p-1.5">
                            <span className="h-5 w-5">{collection.icon}</span>
                        </div>
                    )}
                    <div>
                        <h2 className="text-xl font-bold tracking-tight">{collection.title}</h2>
                        {collection.description && <p className="text-sm text-muted-foreground">{collection.description}</p>}
                    </div>
                </div>

            }>
                <div className="flex space-x-3 pb-4 px-1">
                    {collection.items?.map((workshop: Workshop, index: number) => (
                        <WorkshopCard
                            key={index}
                            type={'media'}
                            workshop={workshop}
                            size={cardSize}
                            aspectRatio={aspectRatio}
                            showInstructor
                        />
                    ))}
                </div>
            </HorizontalScrollArea>

            {cta && (
                <div>
                    {cta}
                </div>
            )}
        </div>
    );
}

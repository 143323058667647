import { WorkshopCollectionRow } from '@/components/app/workshop/workshop-collection-row';
import { QuickPicksSection } from '@/components/app/workshop/workshops-quick-picks';
import HomeFeaturedSection from '@/components/app/home/home-featured-section';
import { CategoriesPills } from '@/components/app/workshop/workshop-categories';
import CallToAction, { BecomeAnInstructorCTA } from '@/components/app/call-to-actions';
import routes from '@/lib/routes';
import { useEffect, useState } from 'react';
import { getPast, getQuickPicks, getUpcoming } from '@/services/workshops';

export default function HomeView() {

    const [isLoadingUpcoming, setIsLoadingUpcoming] = useState(true);
    const [isLoadingPast, setIsLoadingPast] = useState(true);
    const [isLoadingQuickPicks, setIsLoadingQuickPicks] = useState(true);


    const [upcomingWorkshops, setUpcomingWorkshops] = useState(null);
    const [pastWorkshops, setPastWorkshops] = useState(null);
    const [quickPicks, setQuickPicks] = useState(null);

    useEffect(() => {
        fetchUpcomingWorkshops();
        fetchPastWorkshops();
        fetchQuickPicks();
    }, []);


    const fetchUpcomingWorkshops = async () => {
        setIsLoadingUpcoming(true);

        const response = await getUpcoming();

        setUpcomingWorkshops(response.data || []);

        setIsLoadingUpcoming(false);
    }

    const fetchPastWorkshops = async () => {
        setIsLoadingPast(true);

        const response = await getPast();

        setPastWorkshops(response.data || []);

        setIsLoadingPast(false);
    }

    const fetchQuickPicks = async () => {
        setIsLoadingQuickPicks(true);

        const response = await getQuickPicks();

        setQuickPicks(response.data || []);

        setIsLoadingQuickPicks(false);
    }


    return (
        <div className="max-w-screen">

            <HomeFeaturedSection />

            <div className={"p-5 space-y-5"}>
                <div className={"sticky top-0 px-0 -mx-3 md:mx-0 z-10 py-2 bg-background/90 background-blur"}>
                <CategoriesPills useLink/>
                </div>

                <QuickPicksSection workshops={quickPicks} isLoading={isLoadingQuickPicks} />

                <WorkshopCollectionRow
                    isLoading={isLoadingUpcoming}
                    collection={{
                    type: 'upcoming_workshops',
                    title: 'Upcoming Workshops',
                    description: 'Explore our collection of upcoming workshops.',
                    items: upcomingWorkshops,
                }} cta={
                    <CallToAction
                        className={"bg-gradient-to-r from-pink-500 via-red-500 to-orange-500 text-white"}
                        title="Get Started"
                        description="Explore our collection of workshops." actions={
                        [
                            {
                                title: 'Browse All Workshops',
                                description: 'Explore our collection of workshops.',
                                link: routes.workshops
                            }
                        ]
                    } />
                } />

                {/*<WorkshopCollectionRow  collection={*/}
                {/*    {*/}
                {/*        type: 'popular_workshops',*/}
                {/*        title: 'Popular Workshops',*/}
                {/*        items: popularWorkshops,*/}
                {/*    }*/}
                {/*} cta={*/}
                {/*    <CallToAction title="Get Started" description="Explore our collection of workshops." actions={*/}
                {/*        [*/}
                {/*            {*/}
                {/*                title: 'Browse All Workshops',*/}
                {/*                description: 'Explore our collection of workshops.',*/}
                {/*                link: routes.workshops*/}
                {/*            }*/}
                {/*        ]*/}
                {/*    } />*/}
                {/*}/>*/}

                <WorkshopCollectionRow
                    isLoading={isLoadingPast}
                    collection={
                    {
                        type: 'past_workshops',
                        title: 'Past Workshops',
                        items: pastWorkshops,
                    }
                } />

                {/*<BecomeAnInstructorCTA />*/}
            </div>
        </div>
    );
}



import { Workshop } from '@/types/app/workshop';
import { Project } from '@/types/app/project';

export default {
    // Workshops
    workshops: {
        upcoming: route('api.workshops.upcoming'),
        past: route('api.workshops.past'),
        // past: route('api.workshops.past'),
        quickPicks: route('api.workshops.quick-picks'),
        popular: route('api.workshops.popular'),
    }
}

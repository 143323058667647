import { Skeleton } from "@/components/ui/skeleton"

export function QuickPicksSkeleton() {
    return (
        <section className="py-8">
            <div className="container px-4">
                <div className="mb-6 flex items-center justify-between">
                    <Skeleton className="h-8 w-40" />
                    <div className="flex items-center gap-2">
                        <Skeleton className="h-9 w-24 hidden sm:block" />
                        <div className="flex">
                            <Skeleton className="h-9 w-9 rounded-r-none" />
                            <Skeleton className="h-9 w-9 rounded-l-none" />
                        </div>
                    </div>
                </div>

                <div className="flex gap-4 overflow-x-auto pb-4">
                    {Array.from({ length: 5 }).map((_, i) => (
                        <div key={i} className="flex-shrink-0">
                            <div className="flex w-[300px] items-center gap-3 rounded-md p-2">
                                <Skeleton className="h-[60px] w-[60px] rounded-md" />
                                <div className="flex-1 space-y-2">
                                    <Skeleton className="h-5 w-3/4" />
                                    <Skeleton className="h-4 w-4/5" />
                                    <Skeleton className="h-3 w-2/3" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}


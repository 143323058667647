import apiRoutes from '@/lib/api-routes';
import { api } from '@/lib/api';

export async function getUpcoming() {
    const response =  await api.get(apiRoutes.workshops.upcoming);

    return response.data;
}

export async function getPast() {
    const response =  await api.get(apiRoutes.workshops.past);

    return response.data;
}

export async function getQuickPicks() {
    const response =  await api.get(apiRoutes.workshops.quickPicks);

    return response.data;
}

export async function getPopular() {
    const response =  await api.get(apiRoutes.workshops.popular);

    return response.data;
}
